import React, { useState } from "react";
import Button from "@mui/material/Button";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const ContactForm = () => {
  const breakpoints = useBreakpoint();

  const formStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    width: breakpoints.sm ? "100%" : 406,
  };

  const inputStyle = {
    boxSizing: "border-box",
    height: 52,
    width: breakpoints.sm ? "100%" : 336,
    border: "1px solid #565656",
    borderRadius: 6,
    backgroundColor: "rgba(255,255,255,0.08)",
    marginTop: 10,
    color: "white",
  };

  const labelStyle = {
    marginTop: 16,
  };

  const sendBtn = {
    height: 40,
    width: 130,
    borderRadius: 6,
    marginTop: 16,
    borderColor: "#FF9800",
    color: "white",
    textTransform: "none",
  };

  const [sent, setSent] = useState(false);

  return (
    <form style={formStyle} action="https://www.flexyform.com/f/1b1c56822d108c7fbdd28ad9bb2c9c1fbdfdaec5" method="post">
      <label style={labelStyle}>Full name</label>
      <input type="text" name="fullname" style={inputStyle} />

      <label style={labelStyle}>Email</label>
      <input type="email" name="email" style={inputStyle} />

      <label style={labelStyle}>Message</label>
      <textarea
        name="message"
        rows="5"
        style={{ ...inputStyle, height: 104 }}
      />

      <input type="hidden" name="_recaptcha" id="_recaptcha"/>
      <script src="https://www.google.com/recaptcha/api.js?render=6Lf7UsoUAAAAACT2Z6gLyh7RTDfyYGxfZ-M4D0ph"/>
      <script src="https://www.flexyform.com/js/recaptcha.js"/>

      <Button onClick={() => setSent(true)} variant="outlined" style={sendBtn} type="submit">
        <p>Send</p>
      </Button>

      {sent ? (
        <h4 style={{ color: "rgb(132, 245, 153)" }}>Thank you for contacting us!</h4>
      ) : (
        <></>
      )}
    </form>
  );
};

export default ContactForm;
